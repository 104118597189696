export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    Account: ['OrgAccount', 'UserAccount'],
    BaseUser: ['CurrentUser', 'User'],
    ConditionedStatus: [
      'CompositeResourceClaimStatus',
      'CompositeResourceDefinitionStatus',
      'CompositeResourceStatus',
      'CompositionStatus',
      'ConfigurationRevisionStatus',
      'ConfigurationStatus',
      'CustomResourceDefinitionStatus',
      'ManagedResourceStatus',
      'ProviderConfigStatus',
      'ProviderRevisionStatus',
      'ProviderStatus',
    ],
    ConfigurationOrProvider: ['Configuration', 'Provider'],
    CreateControlPlaneResponse: ['AccountControlPlane', 'ControlPlaneLimitReached'],
    DetailsResource: ['CompositeResource', 'CompositeResourceClaim', 'ManagedResource'],
    KubernetesResource: [
      'CompositeResource',
      'CompositeResourceClaim',
      'CompositeResourceDefinition',
      'Composition',
      'ConfigMap',
      'Configuration',
      'ConfigurationRevision',
      'CustomResourceDefinition',
      'GenericResource',
      'ManagedResource',
      'Provider',
      'ProviderConfig',
      'ProviderRevision',
      'Secret',
    ],
    ManagedResourceDefinition: ['CustomResourceDefinition'],
    McpResource: ['McpClaim', 'McpComposite', 'McpManagedResource'],
    Node: [
      'CompositeResource',
      'CompositeResourceClaim',
      'CompositeResourceDefinition',
      'Composition',
      'ConfigMap',
      'Configuration',
      'ConfigurationRevision',
      'CustomResourceDefinition',
      'Event',
      'GenericResource',
      'ManagedResource',
      'Provider',
      'ProviderConfig',
      'ProviderRevision',
      'Secret',
    ],
    ProviderConfigDefinition: ['CustomResourceDefinition'],
    RegistryResource: ['RegistryCRD', 'RegistryXRD'],
  },
};
export default result;
